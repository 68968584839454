

.title {
  font-weight: 900;
  font-size: '34px';
  margin-bottom: var(--mantine-spacing-md);

  @media (max-width: '768px') {
    font-size: '32px';
  }
}

.control {
  @media (max-width: '768px') {
    width: 100%;
  }
}

.mobileImage {
  @media (min-width: 48em) {
    display: none;
  }
}

.desktopImage {
  @media (max-width: 47.99em) {
    display: none;
  }
}