.titleLayout {
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: 600 !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.programCard {
  width: 100%;
  border-radius: 15px !important;
  padding: 8px !important;
  cursor: pointer;
}

.contentCard {
  padding: 8px;
  @media(max-width: 768px) {
    padding: 4px !important;
  }
}